<template>
  <div style="height: 100%">
    <div class="header" style="margin-bottom: 20px">
      <div class="header_top">
        <img src="../../assets/user.png" alt="" />
        <span class="center">联行号查询</span>
      </div>
    </div>
    <el-card>
      选择银行：
      <el-select
        v-model="value"
        placeholder="请选择银行"
        clearable
        filterable
        style="width:300px"
      >
        <el-option
          v-for="(item, index) in bankOpts"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      支行：
      <el-input
        style="width:300px"
        v-model="keywords"
        placeholder="请输入支行关键字"
      ></el-input>
      <el-button
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-search"
        @click="keywordChange"
      >
        搜索
      </el-button>
      <div style="margin-top:30px;">
        <p v-for="(item, index) in list" :key="index" class="list-item">
          <span>支行名称：{{ item.label }}</span>
          <span>联行号：{{ item.value }}</span>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { api } from '../../api';
export const getAllbanks = api()('getAllbanks.json');
export const bankListBranchCode = api()('bank.listBranchCode.json');
export default {
  data() {
    return {
      value: '',
      bankOpts: [],
      keywords: '',
      list: []
    };
  },
  created() {
    this.getAllbanks();
  },
  methods: {
    async getAllbanks() {
      const res = await getAllbanks({});
      console.log(res);
      this.bankOpts = res.data;
    },
    async keywordChange() {
      const res = await bankListBranchCode({
        BankNo: this.value,
        KeyWord: this.keywords,
        $ignoreRepeat: true
      });
      this.list = res;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;
  font-size: 14px;
  color: #606266;
}
</style>
